import styled, { device, themeGet } from "@styled";
import BackgroundImage from "gatsby-background-image";

export const PageHeaderWrap = styled(BackgroundImage)`
  background-color: ${themeGet("colors.spring")};
  text-align: center;
  h1 {
    font-style: italic;
    text-transform: uppercase;
    font-weight: 900;
    font-size: clamp(40px, 6.3vw, 120px);
  }
  padding-block: 80px;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  ${device.large} {
    padding-block-start: 136px;
    padding-block-end: 131px;
  }
`;
