import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import PageHeader from "@ui/page-header/layout-02";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import TestimonialArea from "@containers/sonido-y-luces/testimonial-area";
import SolutionArea from "@containers/sonido-y-luces/solution-area";

const SonidoYLucesPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Sonido y Luces" />
      <Header transparent />
      <main className="site-wrapper-reveal">
        <PageHeader title="Sonido y Luces" />
        <SolutionArea />
        <TestimonialArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default SonidoYLucesPage;
