import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import SolutionBox from "@components/box-image/layout-09";
import MessageBox from "@components/message-box";
import { SectionTitleType, ItemType, MottoType } from "@utils/types";
import { SectionWrap, StyledRow } from "./style";

import icon1 from "../../../assets/images/icons/iluminacion.png";
import icon2 from "../../../assets/images/icons/ing-sonido.png";
import icon3 from "../../../assets/images/icons/alquiler-sonido.png";
import icon4 from "../../../assets/images/icons/backline.png";
import icon5 from "../../../assets/images/icons/staff-djs.png";
import icon6 from "../../../assets/images/icons/sonido-en-vivo.png";

const SolutionArea = () => {
  const solutions = [
    { title: "Iluminación Profesional", icon: icon1 },
    { title: "Ingeniería de Sonido", icon: icon2 },
    { title: "Alquiler de Sonido", icon: icon3 },
    { title: "Backline", icon: icon4 },
    { title: "Staff de Djs", icon: icon5 },
    { title: "Sonido en Vivo", icon: icon6 },
  ];
  return (
    <SectionWrap>
      <Container>
        <SectionTitle
          mb={["30px", null, "60px"]}
          title="Sonido y Luces"
          subtitle="DK Catering & Eventos ofrece una variedad de servicios de sonido e iluminación"
        />
        <StyledRow>
          {solutions.map((solution, i) => (
            <SolutionBox
              key={`solution-${i}`}
              title={solution.title}
              image={solution.icon}
            />
          ))}
        </StyledRow>
        <Row pt="80px">
          <Col xl={12}>
            <MessageBox
              text="No dudes en escribirnos ¡nuestro amable personal estará encantado de ayudarte!"
              link="https://api.whatsapp.com/send?phone=51981182525&text=Hola!%20Vengo%20desde%20la%20web,%20tengo%20una%20consulta!"
              linkText="Conversemos"
            />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

SolutionArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    motto: PropTypes.shape(MottoType),
  }),
};

export default SolutionArea;
