import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "@ui/wrapper";
import { useStaticQuery, graphql } from "gatsby";
import Image from "@ui/image";
import { ImageType, HeadingType, TextType } from "@utils/types";
import {
  StyledSection,
  StyledTestimonial,
  StyledReview,
  StyledAuthorName,
} from "./style";

const TestimonialArea = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "cabina.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <StyledSection>
      <StaticImage
        src="../../../assets/images/cabina-shape.png"
        className="testimonial-shape"
        alt="Testimonidal"
      />
      <Container fluid>
        <Row alignItems="center">
          <Col md={6}>
            <div className="image text-center">
              <Image src={data.file} alt="cabina" />
            </div>
          </Col>
          <Col md={6}>
            <StyledTestimonial>
              <StyledReview>
                Hacemos realidad tu visión con equipos de última generación y
                técnicos expertos, nos adaptamos a eventos de todos los tamaños
                y presupuestos. Nuestro departamento de sonido y luces está
                equipado con una línea completa de equipos de sonido JBL y luces
                Robe.
              </StyledReview>
              <div className="author">
                <StyledAuthorName>Mario Rivera</StyledAuthorName>
                <p>/ Especialista en Sonido y Luces</p>
              </div>
            </StyledTestimonial>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

TestimonialArea.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
  }),
};

export default TestimonialArea;
